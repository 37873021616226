.App {
  flex-direction: column;
}

.content {
  background-color: #f3f3f3;
}

.zoom {
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.1); /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.toonCard {
  .modal-content { 
    background-color: unset;
    border: unset;
  }
  
  background-color: white;
  box-shadow: 5px 2px 2px rgba(0, 0, 0, 0.438);
  border-radius: 15px;
  padding: 30px;
  h1 {
    font-family: 'Passion One', cursive;
  }
}

.toonCard--account {
  background-image: url("./components/img/backgroundimage-1.png");
  border: solid 5px white;
}

.button {
  font: bold 30px "Minnie";
  text-align: center;
  background-color: #224577;
  border: 4px solid #fefefe;
  color: black;
  padding: 20px 100px;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-jouer {
  background-color: #E12727;
  color: #fedf34;
  border: unset;
  box-shadow:
  0 0 0 5px #000000,
  5px 2px 10px rgba(0, 0, 0, 0.438);
}

.button--normal {
  &:hover {
    background-color: #FFF;
    color: #D68101;
    border-color: transparent;
    text-decoration: none;
    -webkit-box-shadow:inset 0px 0px 0px 0px #FDA033 ;
    -moz-box-shadow:inset 0px 0px 0px 0px #FDA033 ;
    box-shadow:inset 0px 0px 0px 0px #FDA033 ;
  }

  &:focus{
    color: #333333;
    background-color: #FDA033;
    border-color: #D68101;
  }

  &:not(:disabled):not(.disabled):active {
    color: #333333;
    background-color: #FDA033;
    border-color: #D68101;
  }
  &:disabled {
    color: #333333;
    background-color: #FDA033;
    border-color: #D68101;
  }
}