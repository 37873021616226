body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
  background-image: url(/static/media/backgroundimage-3.f2796ab6.png);
  background-size: 150px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Mickey';
  src: local('Mickey'), url(/static/media/MICKEY.b12c6853.TTF) format('truetype');
}

@font-face {
  font-family: 'Minnie';
  src: local('Minnie'), url(/static/media/Minnie.d64bb188.TTF) format('truetype');
}

.App{flex-direction:column}.content{background-color:#f3f3f3}.zoom{transition:-webkit-transform .2s;transition:transform .2s;transition:transform .2s, -webkit-transform .2s}.zoom:hover{-webkit-transform:scale(1.1);transform:scale(1.1)}.toonCard{background-color:white;box-shadow:5px 2px 2px rgba(0,0,0,0.438);border-radius:15px;padding:30px}.toonCard .modal-content{background-color:unset;border:unset}.toonCard h1{font-family:'Passion One', cursive}.toonCard--account{background-image:url(/static/media/backgroundimage-1.600b3125.png);border:solid 5px white}.button{font:bold 30px "Minnie";text-align:center;background-color:#224577;border:4px solid #fefefe;color:black;padding:20px 100px;border-radius:10px;transition:color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out}.button-jouer{background-color:#E12727;color:#fedf34;border:unset;box-shadow:0 0 0 5px #000,5px 2px 10px rgba(0,0,0,0.438)}.button--normal:hover{background-color:#FFF;color:#D68101;border-color:transparent;text-decoration:none;box-shadow:inset 0px 0px 0px 0px #FDA033}.button--normal:focus{color:#333333;background-color:#FDA033;border-color:#D68101}.button--normal:not(:disabled):not(.disabled):active{color:#333333;background-color:#FDA033;border-color:#D68101}.button--normal:disabled{color:#333333;background-color:#FDA033;border-color:#D68101}


.navbar-frenchy .mobile{display:none}@media only screen and (max-width: 992px){.navbar-frenchy .desktop{display:none}.navbar-frenchy .eyes{width:80%}.navbar-frenchy .mobile{display:inherit}.navbar-frenchy .navbar-nav{background-color:#224577;padding:10px;border-radius:0% 0% 12px 12px;border-left:solid 5px black;border-right:solid 5px black;border-bottom:solid 5px black}}.navbar-frenchy{height:70px;max-width:100rem;margin-right:auto;margin-left:auto;background-color:#224577;border-radius:0% 0% 12px 12px;box-shadow:0 0 0 5px #000000, 0 0 0 10px #fefefe}.navbar-frenchy .nav-link{padding-left:50px}.navbar-frenchy a{font-family:'Minnie';font-size:27px;margin-top:15px;padding:0;padding-right:15px;color:#fefefe}.navbar-frenchy a:hover{color:#fff;text-decoration:none;box-shadow:0 4px}.navbar-frenchy .activePage{box-shadow:0 4px}.navbar-frenchy a.imgLogo{margin-top:unset}.navbar-frenchy a.imgJouer img{padding-top:95px}.navbar-frenchy a.imgJouer:hover{box-shadow:unset;-webkit-transform:rotate(360deg);transform:rotate(360deg);transition-delay:0;transition:all 0.6s}.navbar-frenchy a.imgJouer:not(:hover){-webkit-transform:rotate(0deg);transform:rotate(0deg);transition-delay:0;transition:all 0.6s}

.content--mininews{padding-top:10px;padding-bottom:10px;padding-left:20%}.content--mininews .row{margin-right:0px}.content--mininews .newsHead{font-family:'Minnie';font-size:27px;color:black}.content--mininews .newsLink{font-family:'Minnie';font-size:27px;color:black}.content--mininews .newsLink:hover{text-decoration:none;box-shadow:0 4px}.content--mininews .toonCard--miniNews{display:flex;border-radius:15px;padding:unset;width:80%}.content--mininews .toonCard--miniNews .newsImg{width:400px;display:inline;border-radius:15px 0px 0px 15px}.content--mininews .toonCard--miniNews .newsContent{float:right;padding:10px}.content--mininews .textMiniNews{padding-top:5%;padding-left:50px}@media only screen and (max-width: 1300px){.content--mininews{align:center;padding-left:10%}.content--mininews .toonCard--miniNews{min-width:300px;max-width:300px;display:block}.content--mininews .toonCard--miniNews .newsImg{width:300px;display:block;border-radius:15px 15px 0px 0px}.content--mininews .toonCard--miniNews .newsContent{float:unset}}

.content--acceuil{background-color:unset;padding-bottom:20px}.content--acceuil .logo{text-align:center}.content--acceuil .logo img{padding-top:20px;width:60%;height:auto}.content--acceuil .toonImgAcceuil{width:100%}.content--acceuil .toonCard--infoFr{background-color:#224577;color:white;border:solid 5px white;text-align:center}.content--acceuil .toonCard--infoFr .infoFrText{text-align:left;margin-bottom:30px}@media only screen and (max-width: 768px){.toonImgAcceuil{display:none}}

.footer{background-color:#5185e3;text-align:center;padding-top:20px}.footer h1{color:#f3f3f3;font-family:'Passion One', cursive}.footer .icons{width:100%}.footer .icons>a{color:black}.footer .icons>a svg{transition:-webkit-transform .3s;transition:transform .3s;transition:transform .3s, -webkit-transform .3s}.footer .icons>a:hover svg{-webkit-transform:scale(1.2);transform:scale(1.2)}

.content--jouer{background-color:unset;margin:10%;margin-top:100px}.content--jouer .toonCard--play .infoDlTop .telecharger{padding-bottom:40px}.content--jouer .toonCard--play .infoDlTop .telecharger a:hover{color:#fedf34;text-decoration:none}.content--jouer .toonCard--play .toonPlay{width:10%}.content--jouer .toonCard--play .patchNote{padding-bottom:20px}.content--jouer .toonCard--play .requirements{padding-bottom:20px}.content--jouer .toonCard--play .dl{padding-top:20px}.content--jouer .toonCard--play .button-telecharger{font-size:20px;padding:20px 50px;background-color:#E12727;color:#fedf34;border:unset;box-shadow:0 0 0 5px #000,5px 2px 10px rgba(0,0,0,0.438)}@media (max-width: 560px){.content--jouer .toonCard--play .button-telecharger{font-size:15px}}@media (min-width: 768px){.content--jouer .row .col-md-5{flex:unset;max-width:100%;width:100%}}@media (min-width: 1200px){.content--jouer .container,.content--jouer .container-sm,.content--jouer .container-md,.content--jouer .container-lg,.content--jouer .container-xl{max-width:100%}.content--jouer .row .col-md-5{flex:0 0 41.666667%;max-width:50%}}@media only screen and (max-width: 1470px){.toonPlay,.disparais{display:none}}

