.navbar-frenchy {
    .mobile {
        display: none;
    }    
}

@media only screen and (max-width: 992px) {
    .navbar-frenchy {
        .desktop {
            display: none;
        }

        .eyes {
            width: 80%;
        }

        .mobile {
            display: inherit;
        }

        .navbar-nav {
            background-color: #224577;
            padding: 10px;
            border-radius: 0% 0% 12px 12px;
            border-left: solid 5px black;
            border-right: solid 5px black;
            border-bottom: solid 5px black;
        }
    }

}

.navbar-frenchy {
    height: 70px;
    max-width: 100rem;
    margin-right: auto;
    margin-left: auto;
    background-color: #224577;
    border-radius: 0% 0% 12px 12px;
    box-shadow:
    0 0 0 5px #000000,
    0 0 0 10px #fefefe;
    
    .nav-link {
        padding-left: 50px;
    }

    a {
        font-family: 'Minnie';
        font-size: 27px;
        margin-top: 15px;
        padding: 0;
        padding-right: 15px;
        color: #fefefe;
        &:hover {
            color: rgb(255, 255, 255);
            text-decoration: none;
            box-shadow: 0 4px;
        }
    }

    .activePage {
        box-shadow: 0 4px;
    }

    a.imgLogo {
        margin-top: unset;
    }

    a.imgJouer {
        img {
            padding-top: 95px;
        }
        &:hover {
            box-shadow: unset;
            transform: rotate(360deg);
            transition-delay: 0;
            transition: all 0.6s;
        }
        &:not(:hover) {
            transform: rotate(0deg);
            transition-delay: 0;
            transition: all 0.6s;
        }
    }
}

.nav-container{
}
